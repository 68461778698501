{
  box-sizing: border-box;
}

Button {
  background-color: #007BFF;
	color: #333;
	font-size: 1.5rem;
	padding: 3px 30px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}
Button:hover {
  background-color: #0069D9;
}
.cockerbutton {
  background-color: "white";
	color: #333;
	font-size: 1.5rem;
	padding: 3px 30px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}
.cockerbutton:hover {
  background-color: #0069D9;
}
.ButtonGroup {
  display: flex;
	gap: 3px;
	flex-direction: row;
	text-align: center;
	justify-content: center;
	align-items: stretch;
	position: relative;
  margin: 10px 0px;
}  

.sussy {
  text-align: left;
  margin: 10px 0px;
}

table {
  position: relative;
  top: 30px;
  text-align: center;
  justify-content: center;
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
tfoot {
  border-top: 2px solid black;
  font-weight: bolder;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr {
  font-family: Arial;
}
table tr:nth-child(even) {
  background-color: #F2F2F2;
}
table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.Leaderboard {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  text-align: center;
  justify-content: center;
}

.Leaderboard2 {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  text-align: center;
  color: white;
  justify-content: center;
}
.Leaderboard2 table tr:nth-child(even) {
  background-color: #0C344A;
}
.Leaderboard2 table tr:nth-child(odd) {
  background-color: #0C344A;
}
.Leaderboard2 table tfoot {
  border-top: 3px solid #66C9FF;
  background-color: #68426A;
  font-weight: bold;
}

.Leaderboard2 table {
  position: relative;
  bottom: 15px;
  top: 2px;
  text-align: center;
  justify-content: center;
  border-spacing: 0;
  border: 2px solid #0C344A;
}
.Leaderboard2 table th,
.Leaderboard2 table td {
  margin: 0;
  padding: 0.2rem;
  font-size: 1rem;
  text-align: left;
  border-bottom: 1px solid #66C9FF;
  border-right: 1px solid #0C344A;
  position: relative;
}
.Leaderboard2 table th.sort-desc::before {
  border-top: 5px solid #FFFFFF;
}
.Leaderboard2 table th.sort-asc::before {
  border-bottom: 5px solid #FFFFFF;
}

.Leaderboard3 {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  text-align: center;
  color: white;
  justify-content: center;
}
.Leaderboard3 table tr:nth-child(even) {
  background-color: #5F1411;
}
.Leaderboard3 table {
  position: relative;
  bottom: 15px;
  top: 2px;
  text-align: center;
  justify-content: center;
  border-spacing: 0;
  border: 2px solid #320A0B;
}
.Leaderboard3 table tr
{
  background-color: #5F1411;
}
.Leaderboard3 table th,
.Leaderboard3 table td {
  margin: 0;
  padding: 0.2rem;
  font-size: 1rem;
  text-align: left;
  border-bottom: 1px solid #FF7C77;
  border-right: 1px solid #5F1411;
  position: relative;
}
.Leaderboard3 table th.sort-desc::before {
  border-top: 5px solid #FFFFFF;
}
.Leaderboard3 table th.sort-asc::before {
  border-bottom: 5px solid #FFFFFF;
}
.Leaderboard3 table tfoot {
  border-top: 3px solid #FF7C77;
  background-color: #68426A;
  font-weight: bold;
}

.Leaderboard4 {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  text-align: center;
  color: white;
  justify-content: center;
}
.Leaderboard4 table tr:nth-child(even) {
  background-color: #68426A;
}
.Leaderboard4 table {
  position: relative;
  bottom: 15px;
  top: 2px;
  text-align: center;
  justify-content: center;
  border-spacing: 0;
  border: 2px solid #320A0B;
}
.Leaderboard4 table tr
{
  background-color: #68426A;
}
.Leaderboard4 table th,
.Leaderboard4 table td {
  margin: 0;
  padding: 0.2rem;
  font-size: 1rem;
  text-align: left;
  border-bottom: 1px solid #FFFFFF;
  border-right: 1px solid #68426A;
  position: relative;
}
.Leaderboard4 table th.sort-desc::before {
  border-top: 5px solid #FFFFFF;
}
.Leaderboard4 table th.sort-asc::before {
  border-bottom: 5px solid #FFFFFF;
}
.Leaderboard4 table tfoot {
  border-top: 0px solid #FFFFFF;
  background-color: #68426A;
  font-weight: bold;
}

.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
input {
  margin: auto;
  position: relative;
  top: 20px;
  text-align: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}

body {
  margin: 0;
}

.container {
  display: flex;
  margin: auto;
  position: relative;
  top: 20px;
  text-align: center;
  justify-content: center;
}

.container2 {
  flex-direction: column;
  margin: auto;
  width: fixed;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
}


.thing {
  display:flex
}

#outer {
  border: 1px solid red;
  display: flex;
  width: auto;
  margin: auto;
  justify-content: center;
}

.nav {
  background-color: #007BFF;
  color: white;
  display: flex;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 3rem;
  font-family: Arial;
}

.test {
  text-align: left;
  font-size: 1.5rem;
  font-family: Arial;
}


.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  text-align: left;
  gap: 1rem;
}

.nav uli {
  display: flex;
  text-align: left;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: Arial;
  padding: .25rem;
}

.nav li.active {
  background-color: #0062CC;
}

.nav li:hover {
  background-color: #0069D9;
}

.nav2 {
  background-color: #007BFF;
  color: white;
  border-color: #333;
  display: flex;
	gap: 3px;
  padding: 3px 30px;
	border-radius: 5px;
  border-width: 2px;
  border-style: outset;
  border-image: initial;
	flex-direction: row;
	text-align: center;
	justify-content: center;
}

.nav2 ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.5rem;
	padding: 3px 30px;
	border-radius: 5px;
	margin: 0px 0px;
  display: flex;
  flex-direction: row;
	text-align: center;
	justify-content: center;
  gap: 25rem;
}

.nav2 a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: Arial;
  padding: .25rem;
}

.uli2 {
  display: flex;
}

.nav2 li.active {
  background-color: #0062CC;
}

.nav2 li:hover {
  background-color: #0069D9;
}

.nav3 {
  background-color: #007BFF;
  color: white;
  border-color: #333;
  display: flex;
	gap: 3px;
  padding: 3px 30px;
	border-radius: 5px;
  border-width: 2px;
  border-style: outset;
  border-image: initial;
	flex-direction: row;
	text-align: center;
	justify-content: space-evenly;
}

.nav3 ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.5rem;
	padding: 3px 30px;
	border-radius: 5px;
	margin: 0px 0px;
  display: flex;
  flex-direction: row;
	text-align: center;
	justify-content: center;
  gap: 2rem;
}

.nav3 a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: Arial;
  padding: .25rem;
}


.nav3 li.active {
  background-color: #0062CC;
}

.nav3 li:hover {
  background-color: #0069D9;
}

.historytest {
  width: inherit;
  position: relative;
  font-size: 1.2rem;
  top: 2px;
  text-align: left;
  align-items: left;
  justify-content: left;
}

.historytest:nth-child(even)
{
  background-color: #F2F2F2;
}

.amogusslider * {
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
}
.amogusslider {
	display: flex;
	position: relative;
	border: solid 1px gray;
	border-radius: 10px;
	padding: 20px 10px;
	box-shadow: none;
	flex-direction: column;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/
}
.amogusslider .bar {
	display: flex;
}
.amogusslider .bar * {
	transition: all 100ms;
}
.amogusslider .bar.active * {
	transition: none;
}
.amogusslider .bar-left {
	width: 25%;
	background-color: #f0f0f0;
	border-radius: 10px 0px 0px 10px;
	box-shadow: none;
	padding: 4px 0px;
}
.amogusslider .bar-right {
	width: 25%;
	background-color: #f0f0f0;
	border-radius: 0px 10px 10px 0px;
	box-shadow: none;
}
.amogusslider .bar-inner {
	background-color: #007BFF;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: space-between;
	position: relative;
	border: solid 1px black;
	justify-content: space-between;
	box-shadow: none;
}
.amogusslider .bar-inner-left {
	width: 50%;
}
.amogusslider .bar-inner-right {
	width: 50%;
}
.amogusslider .thumb {
	background-color: red;
	position: relative;
	z-index: 1;
	cursor: pointer;
}
.amogusslider .thumb::before {
	content: '';
	background-color: white;
	position: absolute;
	width: 20px;
	height: 20px;
	border: solid 1px black;
	box-shadow: none;
	border-radius: 50%;
	z-index: 1;
	margin: -8px;
	cursor: pointer;
}
.amogusslider .input-type-range:focus + .thumb::after {
	content: '';
	position: absolute;
	top: -4px;
	left: -4px;
	width: 11px;
	height: 11px;
	z-index: 2;
	border-radius: 50%;
	border: dotted 1px black;
	box-shadow: none;
}

.amogusslider .thumb * {
	position: absolute;
	bottom: 25px;
	width: 30px;
	height: 30px;
	left: -15px;
	font-size: 75%;
	text-align: center;
	line-height: 30px;
	background-color: blue;
	border-radius: 50%;
	color: white;
	box-shadow: none;
	display: none;
}
.amogusslider .thumb:active * {
	display: block;
}
.input-type-range {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	opacity: 0;
	pointer-events: none;
}

.amogusslider .ruler {
	margin: 10px 0px -5px 0px;
	display: flex;
	/* display: none; */
}
.amogusslider .ruler .ruler-rule {
	border-left: solid 1px;
	border-bottom: solid 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 5px 0px;
}

.amogusslider .ruler .ruler-rule:last-child {
	border-right: solid 1px;
}

.amogusslider .label {
	display: flex;
	justify-content: space-between;
	padding: 0px;
	margin-top: 10px;
	margin-bottom: -20px;
	/* display: none; */
}
.amogusslider .label-min {
	font-size: 80%;
}
.amogusslider .label-max {
	font-size: 80%;
}

.rowBuffer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
}

.rowBuffer .rowElement {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  margin-top: 15px;
  margin-bottom: 15px;
  width: 350px;
  max-width: 350px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rowBuffer .rowElement {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  margin-top: 15px;
  margin-bottom: 15px;
  width: 350px;
  max-width: 350px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.rowBuffer .rowElement .rowInRow {
  display: flex;
  justify-content: space-between;
  margin-right: 45px;
  margin-left: 45px;
}

.rowBuffer .rowElement .rowInRow2 {
  display: flex;
  justify-content: center;
  margin-right: 45px;
  margin-left: 45px;
}

.sussyColumn {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
}